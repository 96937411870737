<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons">
        <router-link :to="{name: 'admin.loyaltyadvantage.add'}" class="btn btn-add" v-if="checkPermissions(['loyalty_advantage.create'])">
          <span>{{ $t('Loyalty advantage toevoegen') }}</span>
        </router-link>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <div class="table-wrapper">
          <div class="table-element">
            <table class="loyalty-advantages">
              <thead>
              <tr>
                <th class="id">{{ $t('ID') }}</th>
                <th>{{ $t('Titel') }}</th>
                <th>{{ $t('Sterren') }}</th>
                <th>{{ $t('Hoeveelheid') }}</th>
                <th>{{ $t('Aangemaakt') }}</th>
              </tr>
              </thead>

              <tbody v-if="loyaltyAdvantages.length > 0">
              <tr :key="loyaltyAdvantage.id" @click="$router.push({name: 'admin.loyaltyadvantage', params: {id: loyaltyAdvantage.id}})" @contextmenu.prevent.stop="loyaltyAdvantageRightClick($event, loyaltyAdvantage)" v-for="loyaltyAdvantage in loyaltyAdvantages">
                <td class="id">{{ loyaltyAdvantage.id }}</td>
                <td>{{ getTranslation(loyaltyAdvantage).title }}</td>
                <td>{{ loyaltyAdvantage.stars }}</td>
                <td>{{ loyaltyAdvantage.amount + '%' }}</td>
                <td>{{ loyaltyAdvantage.created.setLocale('nl').toFormat('dd/LL/yyyy') }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ $t(`${pageTotal.min} - ${pageTotal.max} van ${total} loyalty advantages`) }}
            </div>

            <div class="buttons">
              <button class="btn-inverse" :disabled="page === 0" @click="prevPage()">Prev</button>
              <button class="btn-inverse" :disabled="loyaltyAdvantages.length !== perPage" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-simple-context-menu element-id="vueSimpleContextMenu" :options="contextMenuOptions" ref="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
// import { DateTime } from 'luxon'
import { notification } from '@/notifications'
import { ApiService } from '@/services/admin/api.service'
import { DateTime } from 'luxon'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: { PageTitle },
  data: function () {
    return {
      search: '',
      contextMenuOptions: [{ id: 'deleteLoyaltyAdvantage', name: 'Loyalty advantage verwijderen' }],
      starsList: {
        0: 'Algemeen',
        1: 1,
        2: 2,
        3: 3,
        4: 4
      }
    }
  },
  async mounted () {
    await this.fetchLoyaltyAdvantages(this.page)
  },
  methods: {
    loyaltyAdvantageRightClick: async function (event, loyaltyAdvantage) {
      if (this.checkPermissions(['loyalty_advantage.delete'])) {
        this.$refs.vueSimpleContextMenu.showMenu(event, loyaltyAdvantage)
      }
    },
    contextOptionClicked: async function (event) {
      await this.deleteLoyaltyAdvantage(event.item.id)
      await this.$store.dispatch('LoyaltyAdvantagesModule/fetchLoyaltyAdvantages')
    },
    deleteLoyaltyAdvantage: async function (loyaltyAdvantageId) {
      ApiService.deleteLoyaltyAdvantage(loyaltyAdvantageId).then((res) => {
        notification('Loyalty advantage succesvol verwijderd.')
      })
    },
    prevPage: async function () {
      if (this.page > 0) {
        await this.fetchLoyaltyAdvantages(this.page - 1)
      }
    },
    nextPage: async function () {
      if (this.loyaltyAdvantages.length === this.perPage) {
        await this.fetchLoyaltyAdvantages(this.page + 1)
      }
    },
    async fetchLoyaltyAdvantages (page) {
      await this.$store.dispatch('LoyaltyAdvantagesModule/fetchLoyaltyAdvantages', { page: page, perPage: this.perPage })
    }
  },
  computed: {
    total: function () {
      return this.$store.state.LoyaltyAdvantagesModule.total
    },
    page: function () {
      return this.$store.state.LoyaltyAdvantagesModule.page
    },
    perPage: function () {
      return this.$store.state.LoyaltyAdvantagesModule.perPage
    },
    loyaltyAdvantages: function () {
      const loyaltyAdvantages = []
      const loyaltyAdvantagesRaw = this.$store.getters['LoyaltyAdvantagesModule/getLoyaltyAdvantages']

      if (loyaltyAdvantagesRaw) {
        loyaltyAdvantagesRaw.forEach((loyaltyAdvantageRow, i) => {
          const loyaltyAdvantage = {}

          for (const prop in loyaltyAdvantageRow) {
            if ((prop === 'created' || prop === 'updated') && loyaltyAdvantageRow[prop] != null) {
              loyaltyAdvantage[prop] = DateTime.fromISO(loyaltyAdvantageRow[prop])
            } else {
              loyaltyAdvantage[prop] = loyaltyAdvantageRow[prop]
            }
          }

          loyaltyAdvantages.push(loyaltyAdvantage)
        })
      }

      return loyaltyAdvantages
    },
    pageTotal: function () {
      let offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      if (offsetMax > this.total) {
        offsetMax = this.total
      }

      return { min: offsetMin, max: offsetMax }
    }
  }
}
</script>
